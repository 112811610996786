.map-area {
    min-height: auto;
    height: auto;
    position: relative;
}
.mapContent {
    width: 100%;
    height: 500px;
    min-height: 500px;
}

.searchResultsSection {
    z-index: 1;
    margin-top: 0px;
    height: 100%;
}

.showResultExport {
    padding-top: 32px;
}

.spanBlock {
    position: absolute;
    top: 16px;
    right: 6px;
}

.spanBlock.up {
    transform: rotate(180deg);
}

.spanBlock.down {
    transform: rotate(0deg);
}

#searchPanel .select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: 2px;
}

.popover__wrapper:hover .popover__content {
    transform: translate(-15px, -20px);
}

.popover__content:before {
    right: calc(68% - 33px);
}

.singleSection{
    margin: 10px;
    padding-top: 26px;
    height: 100%;
}
@media (min-width: 320px) and (max-width: 767px) {
    .singleSection{
        margin: 10px;
        padding-top: 0 !important;
        height: 100%;
    }
}
.storeSingleSection{
    position: relative;
    top: 0%;
    left: 0%;
    height: 100%;
}

.storeSingleSection > i {
    font-size: 40px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.searchMessage {
    height: 32px;
    text-align: center;
}
// @media (max-width: 689px) {
//     .searchMessage {
//         width: 100%;
//         z-index: 100;
//         right: 0%;
//         top: 0px;
//         font-size: 12px;
//         position: absolute;
//         padding: 0px !important;
//         height: 55px;
//     }
// }
// @media (max-width: 600px) {
// .searchMessage {
//     width: 100%;
//     z-index: 100;
//     right: 0%;
//     top: 0px;
//     font-size: 12px;
//     position: absolute;
//     padding: 0px !important;
//     height: 55px;
//     }
// }
.notificationPanel{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 101;
}

.alert-info{
    width: 180px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: .5;
}

.cardShadow .card-body {
    padding: 15px 32px;
}

.storeList{
    height: calc(100% - 100px);
    padding-bottom: 15px;
}
.loading-process{
    padding-left: 25px;
}

#storeDetailsAccordion {
    position: relative;

    h2 {
        // height: 10px;
        // position: absolute;
        // right: 11px;
        // top: 5px;
        // z-index: 999;
        // text-align: center;
        
        button {
            // width: 30px;
            // height: 30px;
            padding: 5px;
            margin: 0;
            text-align: center;
            box-shadow: none;
            background: transparent;  
            .text-overflow-div-style{
                float: left;
            }    
        }
        .accordion-button::after {
            font-family: 'Glyphicons Halflings';
            float: right;
            color: grey;
            font-size: 40px;
            font-weight: bold;
            background: none !important;
        }
    }
}

.location-modal.modal-backdrop{
    align-items: baseline;
    padding: 0;
    .modal-content{
        padding: 15px;
        display: block;
        text-align: center;
        border-radius: 0.3rem;
    }
    .modal-header{
        display: block;
    }
}

.select2-results__option.active-option {
    background-color: #006e52;
    color: #fff;
}

#searchPanel .multiselect-list{
    .multiselect-text{
        font-weight: 600;
    }
    .dropdown-bg{
        background-color: #006e52;
        color: #fff;
    }
    li:hover {
        background-color: #006e52;
        color: #fff;
    }
}

.verticalAlign{
    vertical-align: top;
}