.box-position {
    position: absolute;
    top: 30px;
    min-height: 30  0px;
    // width: 100%;
    box-shadow:  0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding: 15px;
    background-color: #ffffff;
    margin-left: 20px !important;
    z-index: 1;
}
.box-header {
    font-size: 1.30rem;
    letter-spacing: 1px;
    margin-bottom: 10px;
    overflow: hidden;
    text-transform: uppercase;
    border-bottom: none;
    position: relative;
    padding-bottom: 10px;
    display: inline;
    font-family: Texta-bold !important;
}
.box-header:after {
    content: "";
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0.075em;
    transform: skew(-30deg);
    width: 85px;
    background-color: rgba(56, 133, 87, 0.5);
}

.card-container{
    position: relative;
}

.container-block{
    padding: 24px 15px;
    .css-sghohy-MuiButtonBase-root-MuiButton-root,.css-sghohy-MuiButtonBase-root-MuiButton-root:hover{
        background: #006e52;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root{
        color: #006e52;
    }
}

.form-block{
    .was-validated .form-control:valid, .form-control.is-valid, .was-validated .was-validated{
        background: transparent;
    }
    .form-select:valid:not([multiple]):not([size]), .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"]{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    }
    .modal-footer{
        margin-top: 20px;
    }
}

.collapsibleTable{
    margin: 15px -10px !important;
    border-radius: 10px !important;
    background: #eeee !important;
}
.paginationBlock p {
    margin: 0;
}

.dashboard-block{
    padding: 20px 15px;
}
.alert {
    position: relative;
    padding: 0.75rem 1.25rem !important;
    margin-bottom: 1rem !important;
    border: 1px solid transparent;
    border-radius: 0.25rem !important;
}
.alert-danger{
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}

.MuiAlert-icon{
    color: #721c24 !important;
}
.followUp-Button{
        
        background: #006e52 !important;
        float: right !important;
}
.badge-success{
    color: #fff !important;
    background-color: #28a745 !important;
}

.badge-danger{ 
    color: #fff !important;
    background-color: #dc3545 !important;
}

.dashboard-tabs .tab-header .MuiButtonBase-root-MuiTab-root .Mui-selected{
    color: #212224 !important;
}
.dashboard-tabs  .MuiButtonBase-root-MuiTab-root{
    color: #006e52 !important; 
}

.dashboard-tabs .MuiTabs-indicator{
    background-color: #006e52 !important;
}

.wrapText{
    overflow-wrap: anywhere !important;
}

.modal-90w{
    width: 90%;
    max-width: none!important;
}

.dashboard-block .select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 15px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .box-position{
        position: absolute;
        top: 30px;
        min-height: 30  0px;
        // width: 93%;
        box-shadow:  0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        padding: 15px;
        background-color: #ffffff;
        z-index: 1;
        margin: 10px !important;
    }

    .container-block{
        padding: 10px;
    }

}