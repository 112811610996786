#helpModal {
    .modal-body{
        min-height: 350px;
    }
    .select-category .form-select{
        font-weight: 800;
    }
    .text-form{
        font-size: 18px;
    }
    .card-body{
        background-color: #F8F8F8;
        border: 1px solid #ccc;
    }
    .resText{
        font-size: 20px;
        margin-bottom: 20px !important;
        text-transform: capitalize;
    }
    #useMapVideo{
        border-radius: 10px;
    }
    li.list-group-item{
        border: 0;
    }
    .form-label{
        font-weight: 600;
        font-size: 17px;
    }
    .select-category{
        .form-label{
            font-size: 20px;
        }
    }
    .form-text{
        font-size: 15px !important;
        color: #454f57!important;
    }
    ul.howtousehip {
        padding-left:20px;
    }
    ul.howtousehip li::before{
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        width: 6px;
        height: 6px;
        background-color: #006e52;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
    }
    .addtional-help {
        color: #006e52 !important;
    }
}
.dateTimePicker{
    min-width: 225px !important; 
    overflow: hidden;
    input{
        padding: 7px;
    }
}