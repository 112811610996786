.feedback-title {
    border-bottom: 1px solid #dcdcdc;
    padding: .5rem 20px;
    // font-size: 1.5rem;
    margin-bottom: 0;
    font-weight: 600  !important;
    font-family: "Texta-book" !important;
    font-size: 20px !important;
    // font-family: "Noto Sans VF","Noto Sans","Helvetica","Arial",sans-serif !important;
}

h5 .feedback-title{
    margin-top: 0;
    color: #141414;
    // font-weight: 600;
}
 .resText{
        font-size: 20px;
        text-transform: capitalize;
    }
.feedback-content {
    padding: 1.5rem 1.5rem;
    font-family: "Texta-book" !important;

    // font-family: "Noto Sans VF","Noto Sans","Helvetica","Arial",sans-serif !important;
    // font-family: Source Sans Pro,sans-serif !important;

}
.font-regular14{
    font-family: "Texta-book" !important;
    font-size: 19px !important;
}
.font-regular13{
    font-family: "Texta-book" !important;
    font-size: 18px !important;
}
.font-semibold14{
    font-family: "Texta-bold" !important;
    font-size: 19px !important;
}
.text-area {
    position: relative;
}
.textArea-content {
    border: 1px solid #dcdcdc;
    outline: none;
    padding: 18px;
    // font-size: 1.375rem;
    // font-weight: 400;
}

.danger-border {
    border: 2px solid #CD0D0D;
}
.maxCharacter {
    display: block;
    line-height: 1.625rem;
    position: absolute;
    bottom: 10px;
    right: 2%;
    top:auto;
    font-size: 1rem;
    font-weight: 500;
}
.btn-wrapper {
    display: block;
}

.submit-btn {
    font-size: 1rem;
    line-height: 1.2;
    // border: 3px solid;
    display: inline-flex;
    -webkit-box-align: center;
    box-shadow: 0 0.25rem 0.5rem rgba(1,1,1,.25);
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: .1em;
    padding: 0.4em 1em;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .4s ease;
    font-weight: 600;
    background: #005974 !important;
    border-color: transparent;
    color: #fff;
    border-radius: 0;
}
.accordin-btn {
    display: inline;
    border: none;
    padding: 0px 2px;
    // border-bottom: 2px dotted #005974;
    // background-color: #e8eef4;
    font-weight: 600;
    width: 1em;
    height: 1em;
    cursor: pointer;
    color: #005974;
}
// .accordin-btn:hover{
//     background-color: #005974;
//     color: #fff;
// }
.feedback-help-text{
    font-weight: 500;
    margin-bottom: 0;
}

.feedback-link {
    cursor: pointer;
    font-weight: 600;
    color: #005974 !important;
}

.feedback-link:hover {
    color: #005974;
}