.hours-of-operations .select2-container {
    display: inline-block;
}

.form-check-input:checked {
    background-color: #336b46;
    border-color: #336b46;
}

.form-check-inline {
    display: inline-block;
    padding-top: 5px;
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 5px;
    width: 115px;
    // height: 48px;
}

.form-check-input[type="radio"] {
    width: 0.75em;
    height: 0.75em;
    margin-top: 9px;
}

.form-check .form-check-input {
    float: none;
    margin-right: 10px;
}

.time-hours{
    // border: 1px solid #cccccc !important;
    border-radius: 3px !important;
    height: 48px;
    // width: 50%;
    // padding: 10px !important;
}

// .special-timings .time-meridian{

// }

.address-map{
    margin-top: 15px;
}
.helper-text{
    text-align: right;
    width: 100%;
    display: block;
}

.special-timings{
    .form-control{
        appearance: auto;
    }
    .form-control.time-filter{
        appearance: none;
    }
    .time-meridian.form-select{
        display: inline-block;
        width: 65px;
    }
}

.dh-opt-bx{
    border: 0px;
}
.hip-pgm-btn{
    .dh-opt-round{
        width: 120px;
        text-align: center;
        .custom-control-label{
            padding: 5px 8px 0px;
        }
        .form-check-input:checked{
            background-color: #336b46;
            border: 4px solid #ffffff;
        }
        .form-check-input[type="radio"]{
            width: 1em;
            height: 1em;
            margin-top: 9px;
        }
        .form-check-input:checked[type="radio"] {
            background: transparent;
        }
    }
    .dh-opt-active{
        .form-check-input{
            background-color: #336b46;
            border: 4px solid #ffffff;
        }
    }
}

.dh-remove{
    float: right;
}

.switch-margin{
    margin: 0 5px;
}

.button-fade {
    pointer-events: none;
    opacity: 0.5;
}

.ven-reg-pg{
    .form-group, #addMoreFNSNumber {
        margin-bottom: 1rem;
    }
    #fnsNumbersList{
        .form-group {
            margin-bottom: 0px;
        }   
    }
}