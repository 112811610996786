.footer {
    color: #fff;
    border-top: 0.25rem solid #006e52;
    background-color: #F2F2F2;
    // padding-top: 10px;
    // padding-bottom: 25px;
    padding: 10px 10px 25px;
}
.footer-logo-list{
    align-items: center;
    justify-content: space-around;
    text-align: center;
}
@media (min-width: 320px) and (max-width: 767px) {
    .footer-logo-list{
        align-items: center;
        justify-content: space-between;

    }
    .footer P {
        color: #464646 !important;
        font-family: "Texta", "Helvetica", "Arial", "sans-serif";
        text-align: left !important;
        line-height: 1.54;
        font-size: 13px;
        font-weight: normal;
        margin-top: 20px;
    }
}
.footer-logo {
.footer-lg-img {
    // width: 100px;
    color: #ffffff;
    cursor: pointer;
}
}
.footer-list div {
    display: inline-block;
    margin-right: 10px;
}
.footer-list div a {
    font-family: 'Open Sans', sans-serif;
    color: #464646;
    text-decoration-color: #464646;
    font-size: 13px;
    font-weight: bold;
    padding-right: 10px;
    border-right: 2px solid #464646;
}
.border-none {
    border: none !important;
}
.footer-mission-text{
    color: #464646 !important;
    font-family: "Texta", "Helvetica", "Arial", "sans-serif";
    text-align: left !important;
    line-height: 1.54;
    font-size: 13px;
    font-weight: normal;
    margin-top: 20px;
    padding-left: 5px;
}
.footerIcons {
    font-size: 0.95rem !important;
    padding-right: 2px;
    fill: #464646  !important;
}

@media (min-width: 992px) and (max-width: 1400px) {
    .logo-snap-ed {
        width: 120px;
    }
    .dta-logo {
        width: 90px;
    }
    .logo-responsive {
        width: 98px;
        height: 72px;
    }
    .mass-logo {
        width: 90px;
        height: auto;
        max-width: 100%;
    }
}