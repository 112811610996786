.green-wrapper {
  display: block;
  background-color: #388557;
  color: #ffffff;
}
.wrapper-banner {
    padding: 6px 16px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    text-align: left;
    font-family: "Noto Sans VF","Noto Sans","Helvetica","Arial";
    font-size: 13.333px;
  }
.div-center {
  display: flex;
  align-items: center;
}
.banner-logo{
  display: inline-block;
  margin-top: -3px;
  margin-right: 3px;
}
.banner-logo-dark {
  filter: brightness(0) invert(1);
  height: 20px;
  width: 20px;
}
.banner-text {
  // margin: 0;
  // padding: 5px 0 5px;
  // margin-left: 2px;
  // line-height: initial;
  // font-weight: 350;
  font-family: "Texta-book","Noto Sans VF","Noto Sans","Helvetica","Arial";
  font-size: 15.3333px;
}
.banner-subtext{
  padding-right: 11px;font-size: 16px !important;
}
.font-state-banner {
  a:hover {
    text-decoration: unset;
  }
}
.button-icon--quaternary {
  min-height: 0px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  text-transform: none;
  border-bottom: 2px solid rgba(20, 85, 143, 0.5);
  letter-spacing: 0;
  border-bottom-color: rgba(255, 255, 255, 0.5);
  color: #ffffff;
}
.button-icon--quaternary:hover {
  border-bottom: 2px solid #ffffff;
}
.font-state-how {
  font-size: 14.66px;
  // font-weight: 550;
  font-family: 'Texta-bold';
}
@media (min-width: 320px) and (max-width: 767px) {
  .font-state-how {
    font-size: 12.66px;
    font-weight: 550;
    font-family: "Noto Sans","Helvetica","Arial";
  }
}
.img-updown {
  position: relative;
  width: 12px;
  transition: all 1s ease;
}
.img-downup {
  position: relative;
  width: 12px;
  transition: all 1s ease;
}

.img-animation {
  transform: rotate(180deg);
}
.green-banner-contents-wrapper {
  display: flex;
  margin: 5px 10px 0px 0;
}  
.green-banner-contentsdiv1p2 {
  margin-top: 0.2em;
}
.mass-gov-banner-icon {
  margin-right: 5px;
  margin-top: 4px;
  img {
    height: 32px;
  }
}
.green-banner-contents {
  margin-top: 0.2em;
  margin-left: 0.5em;
  color: #fff;
  p {
    margin: 0;
  }
}
.content-wrapper{
  margin-left: 16px; 
}


@media (min-width: 320px) and (max-width: 767px) {
  .wrapper-banner {
    padding: 8px 12px 0;
  }
  .banner-logo-dark{
    height: 27px;
    width: 27px;
    vertical-align: baseline;
    margin-top: 5px;
    margin-right: 10px;
  }
  .mass-gov-banner-icon img {
    width: 25px;
    height: 25px;
  }
}