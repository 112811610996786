.yellow-wrapper {
    display: block;
    background-color: #F6C51B;
    color: #ffffff;   
  }
  #yellowBanner  p {
    font-family: "Texta-book", "Helvetica", "Arial", "sans-serif";
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    padding: 15px 0;
    margin: 0;
    color: #141414;
  }
  .yellow-wrapper-banner {
      padding: 6px 16px;
      margin-right: auto;
      margin-left: auto;
      align-items: center;
      text-align: left;
      // font-family: "Noto Sans VF","Noto Sans","Helvetica","Arial";
      font-size: 13.333px;
    }
    .ml-08 {
        margin-left: 0.8rem;
    }
    .alert-fnt {
        font-size: 1.375rem;
    }
  .div-center {
    display: flex;
    align-items: center;
  }
  
  .banner-text {
    // margin: 0;
    // padding: 5px 0 5px;
    // margin-left: 2px;
    // line-height: initial;
    // font-weight: 350;
    font-family: "Texta-book","Noto Sans VF","Noto Sans","Helvetica","Arial";
    font-size: 15.3333px;
  }
  .banner-subtext{
    padding-right: 15px;
  }
  .font-state-banner {
    a:hover {
      text-decoration: unset;
    }
  }
  .button-icon--quaternary {
    min-height: 0px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    text-transform: none;
    border-bottom: 2px solid rgba(20, 85, 143, 0.5);
    letter-spacing: 0;
    border-bottom-color: rgba(255, 255, 255, 0.5);
    color: #ffffff;
  }
  .button-icon--quaternary:hover {
    border-bottom: 2px solid #ffffff;
  }
  .font-state-how {
    font-size: 11.66px;
    font-weight: 550;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .font-state-how {
      font-size: 12.66px;
      font-weight: 550;
      font-family: "Noto Sans","Helvetica","Arial";
    }
  }
  .img-updown {
    position: relative;
    width: 12px;
    transition: all 1s ease;
  }
  .img-downup {
    position: relative;
    width: 12px;
    transition: all 1s ease;
  }
  
  .box {
    opacity: 1;
    transition: all 1s ease-out;
    visibility: visible;  
  }
  
  .hide {
    visibility: hidden;
    transition: all 1s ease-out;
    opacity: 0;  
  }
  .green-banner-contents-wrapper {
    display: flex;
    margin: 5px 10px 0px 0;
  }  
  .green-banner-contentsdiv1p2 {
    margin-top: 0.2em;
  }
  .mass-gov-banner-icon {
    margin-right: 5px;
    margin-top: 4px;
    img {
      height: 32px;
    }
  } 
  .content-wrapper{
    margin-left: 16px; 
  }
  .alert-btn {
    button {
      font-family: "Texta-book", "Helvetica", "Arial", "sans-serif" !important;
    }
  }
  .alert-txt {
    letter-spacing: .1em;
    white-space: nowrap;
    color: rgba(20,20,20,0.8);
    font-weight: 500;
    font-size: 1.2rem;
    // font-family: "Noto Sans VF","Noto Sans","Helvetica","Arial";
    margin: 5px 0 0 10px;
  }
  .alignCenter {
    max-width: '82em';
    margin-left: 'auto';
    margin-right: 'auto';
  }

  .alert-update {
    margin: 0.5rem 0  0.5rem 0.8rem;
    display: block;
    font-size: 1.38rem;
    // font-weight: 600;
    color: rgba(20,20,20,0.8);
    font-family: 'Texta-bold'
  } 

  .alert-black-button {
    background-color: #000000 !important;
    color: #ffffff !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    padding: 5px 15px !important;
    letter-spacing: 1.5px !important;
    line-height: 1.2;
    font-family: Helvetica,sans-serif !important;
  }
  
  
  @media (min-width: 320px) and (max-width: 767px) {
    .yellow-wrapper-banner {
      padding: 8px 12px 11px 0;
    }
    
    .mass-gov-banner-icon img {
      width: 25px;
      height: 25px;
    }
  }

  @media screen and (min-width:900px) and (max-width:1200px) {
    .content {
      padding: 4rem;
    }
  }
  
  @media screen and (max-width:'660px') {
    .alerts-outer {
      align-items: center;
      flex-direction: column !important;
    }
  
    .alert-txt {
      display: inline-block;
      vertical-align: top;
      margin: unset;
      margin-top: 5px;
    }
  
    .alert-separator {
      display: none;
    }
  
    .alerts-inner {
      display:  inline-block !important;
      margin-top: 3px !important;
      vertical-align: top;
      text-align: center;
    }  
  }
  
  @media screen and (min-width: 0px) and (max-width:659px) {
    .alert-btn {
      button {
        padding: 5px 8px !important;
      }
    }
  }
  
  @media screen and (min-width: 660px) and (max-width:1536px) {
    .alert-btn {
      button {
        padding: 5px 12px !important;
      }
    }
  }