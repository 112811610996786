textarea:focus, input:focus, input[type]:focus, .uneditable-input:focus, span:focus, li:focus, button:focus {   
    // border-color: #006e52;
    // box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px #006e52;
    // outline: 0 none;
}
.map-area {
    min-height: 350px;
    height: calc(100vh - 140px);
}

.topMenu li a {
    font-family: "Texta-bold", "Helvetica", "Arial", "sans-serif";
    color: #464646;
    font-size: 1.25rem;
    height: 100%;
}

.btn-primary {
    border: 1px solid #006e52;
    color: #fff;
    background-color: #006e52;
    font-family: Texta-bold !important;
}

.btn-outline-primary {
    border: 1px solid #006e52;
    color: #006e52;
    background-color: #fff;
    font-family: Texta-bold !important;
}

.inputLabel {
    font-family: Texta-bold;
}
.button-outline {
    padding: 4px 12px !important;
    background: #fff;
    font-size: 1.25rem;
    color: #495057;
    border: 1px solid #006e52;
    font-family: Texta-bold !important;

    &:hover {
        color: #fff;
        background: #006e52;
    }
    &:focus {
        color: #fff;
        background: #006e52;
    }
}