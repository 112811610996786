.card-box{
    border: 1px solid #ddd;
    /* padding: 10px 6px; */
    margin: 5px 10px;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,.3)
}
.down-arrow {
    transform: rotate(355deg);
    color: #fff;
    border: 1px solid #006e52;
    cursor: pointer;
    background: #006e52 !important;
    transition: transform 0.5s ease;
}

.card-details{
    border: 1px solid #006e52 ;
    background-color: #F8F9FA;
}
.card-animation{
    transition: transform 0.5s ease;
}
.card-inner{
    padding-top: 10px;
    padding-bottom: 10px;
}
/* .animatedDiv {
    opacity: 1;
    transition: opacity 0.5s ease-in-out; 
  }
  
  .animatedDiv.hidden {
    opacity: 0;
            transition: opacity 0.5s ease-in-out;
  } */