.blue-wrapper {
    display: block;
    background-color: #14558F;
    color: #ffffff;
    text-align: right;
  }
  .wrapper-bluebanner { 
    padding: 2px 16px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    text-align: right;
    font-size: 13.333px;
  }

  .blue-wrapper .btn-outline-primary  {
    border: 1px solid #fff !important;
    background-color: #14558F !important;
    color: #fff !important;
    // color: #14558F !important;
    // background-color: #fff !important;
  }

  .blue-wrapper .menu-item {
    background: none !important;
    border: none !important;
    color: white !important; 
    padding: 0 !important; 
    font-family: Texta-bold !important;
    font-size: 20px !important;
  }
  
  .menu-item:hover {
    background: none !important;
    border: none !important;
  }

  .menu-item:focus {
    background: none !important;
    border: none !important;

  }
  .menu-item .MuiSelect-select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important ;
  }

  .menu-item .MuiSvgIcon-fontSizeMedium {
    color: white !important;
    font-size: 25px;
    top: calc(50% - 0.37em);
  }

  .menu-content {
    background: none;
    border: none;
    box-shadow: none;
  }
  .blue-header-root {
    box-shadow: none !important;
    display: block !important;
  }
  .nav-item .menu-item .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 !important;
    border: none !important;
    padding-right: 32px !important;
  }

  .blue-header-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .blue-header-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline:hover {
    border: none !important;
  }

  .blue-header-root .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline:before {
    border: none !important;
  }
 .nav-item .menu-item .MuiSvgIcon-root-MuiSelect-icon ,
 .MuiSvgIcon-root-MuiSelect-icon {
  color: #ffffff !important;
  }


.help-menu {
    color: #ffffff !important;
    text-decoration: none;
    font-size: 16px !important;
    cursor: pointer;
    background: none;
    border: none;
}
.help-menu:hover {
  background: none !important;
  border: none !important;
}

.help-menu:focus {
  background: none !important;
  border: none !important;

}
.blue-wrapper .nav-item.dropdown  {
  border: none !important; 
  background: none !important; 
}

.blue-wrapper  .nav-link {
  color: white; 
  background: none; 
  border: none !important; 
  padding: 0.5rem 1rem; 
  text-align: left; 
  display: flex;
  align-items: center;
}

.blue-wrapper .nav-link:hover {
  background: none !important; 
  text-decoration: none !important; 
  color: #ffffff
}
  
  // .fa-question-circle {
  //   margin-left: auto; 
  // }

  .caretIcon-position{
    font-size: 15px;
    margin-left: 4px;
    margin-top: -3px;
  }

  @media (max-width: 600px) {
    .blue-hamburger {
      display: block !important;
      width: 100%;
    }
  
    .blue-menu {
      display: none !important;
    }
  }
  
  @media (min-width: 601px) { 
    .blue-hamburger {
      display: none !important;
    }
    .blue-menu {
      display: block !important;
    }
  }















